var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-sections"},[_c('section',{staticClass:"header-section dark-gradient fullpage-section",attrs:{"id":"headerSection","data-section":"home"}},[_c('animated-background'),(!_vm.loading)?_c('v-container',{staticClass:"fill-height pt-12",class:{ 'align-center': _vm.phoneOnly }},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('h1',{staticClass:"strong-text--text"},[_c('animate-text',{attrs:{"duration":1500,"stop-after":0,"delay":500,"text":_vm.$t('sections.1.title')}})],1)]),_c('v-slide-x-transition',{attrs:{"appear":""}},[_c('h2',{staticClass:"text--text mt-5"},[_c('animate-text',{attrs:{"duration":2500,"delay":2000,"text":_vm.$t('sections.1.subtitle')},on:{"done":function($event){_vm.showSection1Actions = true}}})],1)]),_c('div',{staticClass:"actions",class:{
                    'fade-up-off': !_vm.showSection1Actions,
                    'fade-up-on': _vm.showSection1Actions,
                }},[_c('v-btn',{staticClass:"me-6",attrs:{"color":"strong-text","tile":"","large":_vm.pcOnly,"depressed":"","outlined":""},on:{"click":function($event){return _vm.navigateToLink('#aboutSection')}}},[_vm._v(_vm._s(_vm.$t("sections.actions.about")))])],1)],1):_vm._e(),_c('div',{staticClass:"section-scroll-icon"},[_c('scroll-icon',{on:{"click":function($event){return _vm.scrollToSection(2)}}})],1)],1),_c('home-section',{attrs:{"id":"aboutSection","data-section":"about","headline":_vm.$t('sections.about.title'),"subtitle":_vm.$t('sections.about.subtitle'),"divider":""}},[_c('v-row',[_c('v-col',{attrs:{"data-aos":_vm.pcOnly
                        ? _vm.isRtl
                            ? 'fade-left'
                            : 'fade-right'
                        : 'fade-up',"data-aos-duration":"800","cols":_vm.pcOnly ? 5 : 12}},[_c('v-img',{attrs:{"src":"/assets/design.jpg","contain":"","width":"100%","max-height":"320"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"mt-6 mt-lg-0",attrs:{"data-aos":_vm.pcOnly
                        ? _vm.isRtl
                            ? 'fade-right'
                            : 'fade-left'
                        : 'fade-up',"data-aos-duration":"800","cols":_vm.pcOnly ? 6 : 12}},[_c('h1',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("sections.about.row1.title"))+" ")]),_c('p',{staticClass:"medium-text text-light--text mt-6 mt-lg-8",class:{ 'text-center': _vm.phoneOnly }},[_vm._v(" "+_vm._s(_vm.$t("sections.about.row1.content"))+" ")])])],1),_c('v-row',{staticClass:"flex-row-reverse",staticStyle:{"margin-top":"3.5rem"}},[_c('v-col',{attrs:{"data-aos":_vm.pcOnly
                        ? _vm.isRtl
                            ? 'fade-right'
                            : 'fade-left'
                        : 'fade-up',"data-aos-duration":"800","cols":_vm.pcOnly ? 5 : 12}},[_c('v-img',{attrs:{"src":"/assets/build.jpg","contain":"","width":"100%","max-height":"320"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"mt-6 mt-lg-0",attrs:{"data-aos":_vm.pcOnly
                        ? _vm.isRtl
                            ? 'fade-left'
                            : 'fade-right'
                        : 'fade-up',"data-aos-duration":"800","cols":_vm.pcOnly ? 6 : 12}},[_c('h1',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("sections.about.row2.title"))+" ")]),_c('p',{staticClass:"medium-text text-light--text mt-6 mt-lg-8",class:{ 'text-center': _vm.phoneOnly }},[_vm._v(" "+_vm._s(_vm.$t("sections.about.row2.content"))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"data-aos":_vm.pcOnly
                        ? _vm.isRtl
                            ? 'fade-left'
                            : 'fade-right'
                        : 'fade-up',"data-aos-duration":"800","cols":_vm.pcOnly ? 5 : 12}},[_c('v-img',{attrs:{"src":"/assets/deploy.jpg","contain":"","width":"100%","max-height":"320"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"mt-6 mt-lg-0",attrs:{"data-aos":_vm.pcOnly
                        ? _vm.isRtl
                            ? 'fade-right'
                            : 'fade-left'
                        : 'fade-up',"data-aos-duration":"800","cols":_vm.pcOnly ? 6 : 12}},[_c('h1',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("sections.about.row3.title"))+" ")]),_c('p',{staticClass:"medium-text text-light--text mt-6 mt-lg-8",class:{ 'text-center': _vm.phoneOnly }},[_vm._v(" "+_vm._s(_vm.$t("sections.about.row3.content"))+" ")])])],1)],1),_c('cols-section',{attrs:{"id":"skillsSection","headline":"Skills","subtitle":"Our team has a wide range of skills and expertise to help you achieve your goals.","data-section":"skills","cols":_vm.skillsCols}}),_c('the-footer'),_c('scroll-to-top')],1)}
var staticRenderFns = []

export { render, staticRenderFns }