<template>
    <div class="page page-sections">
        <section
            id="headerSection"
            data-section="home"
            class="header-section dark-gradient fullpage-section"
        >
            <animated-background />
            <v-container
                v-if="!loading"
                class="fill-height pt-12"
                :class="{ 'align-center': phoneOnly }"
            >
                <v-slide-y-transition appear>
                    <h1 class="strong-text--text">
                        <animate-text
                            :duration="1500"
                            :stop-after="0"
                            :delay="500"
                            :text="$t('sections.1.title')"
                        />
                        <!-- <span class="secondary--text font-weight-medium">BlockTech</span> -->
                    </h1>
                </v-slide-y-transition>
                <v-slide-x-transition appear>
                    <h2 class="text--text mt-5">
                        <animate-text
                            @done="showSection1Actions = true"
                            :duration="2500"
                            :delay="2000"
                            :text="$t('sections.1.subtitle')"
                        ></animate-text>
                        <!-- <div>Information Technology & Electronic Business Company</div> -->
                    </h2>
                </v-slide-x-transition>
                <div
                    class="actions"
                    :class="{
                        'fade-up-off': !showSection1Actions,
                        'fade-up-on': showSection1Actions,
                    }"
                >
                    <!-- <v-btn @click="navigateToLink('#productsSection')" color="strong-text" tile :large="pcOnly" depressed outlined class="me-6">{{$t('sections.actions.products')}}</v-btn> -->
                    <v-btn
                        @click="navigateToLink('#aboutSection')"
                        color="strong-text"
                        tile
                        :large="pcOnly"
                        depressed
                        outlined
                        class="me-6"
                        >{{ $t("sections.actions.about") }}</v-btn
                    >
                </div>
            </v-container>

            <div class="section-scroll-icon">
                <scroll-icon @click="scrollToSection(2)" />
            </div>
        </section>
        <home-section
            id="aboutSection"
            data-section="about"
            :headline="$t('sections.about.title')"
            :subtitle="$t('sections.about.subtitle')"
            divider
        >
            <v-row>
                <v-col
                    :data-aos="
                        pcOnly
                            ? isRtl
                                ? 'fade-left'
                                : 'fade-right'
                            : 'fade-up'
                    "
                    data-aos-duration="800"
                    :cols="pcOnly ? 5 : 12"
                    class=""
                >
                    <v-img
                        src="/assets/design.jpg"
                        contain
                        width="100%"
                        max-height="320"
                    ></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                    :data-aos="
                        pcOnly
                            ? isRtl
                                ? 'fade-right'
                                : 'fade-left'
                            : 'fade-up'
                    "
                    data-aos-duration="800"
                    class="mt-6 mt-lg-0"
                    :cols="pcOnly ? 6 : 12"
                >
                    <h1 class="headline">
                        {{ $t("sections.about.row1.title") }}
                    </h1>
                    <p
                        class="medium-text text-light--text mt-6 mt-lg-8"
                        :class="{ 'text-center': phoneOnly }"
                    >
                        {{ $t("sections.about.row1.content") }}
                    </p>
                </v-col>
            </v-row>

            <v-row style="margin-top: 3.5rem" class="flex-row-reverse">
                <v-col
                    :data-aos="
                        pcOnly
                            ? isRtl
                                ? 'fade-right'
                                : 'fade-left'
                            : 'fade-up'
                    "
                    data-aos-duration="800"
                    :cols="pcOnly ? 5 : 12"
                    class=""
                >
                    <v-img
                        src="/assets/build.jpg"
                        contain
                        width="100%"
                        max-height="320"
                    ></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                    :data-aos="
                        pcOnly
                            ? isRtl
                                ? 'fade-left'
                                : 'fade-right'
                            : 'fade-up'
                    "
                    data-aos-duration="800"
                    class="mt-6 mt-lg-0"
                    :cols="pcOnly ? 6 : 12"
                >
                    <h1 class="headline">
                        {{ $t("sections.about.row2.title") }}
                    </h1>
                    <p
                        class="medium-text text-light--text mt-6 mt-lg-8"
                        :class="{ 'text-center': phoneOnly }"
                    >
                        {{ $t("sections.about.row2.content") }}
                    </p>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                    :data-aos="
                        pcOnly
                            ? isRtl
                                ? 'fade-left'
                                : 'fade-right'
                            : 'fade-up'
                    "
                    data-aos-duration="800"
                    :cols="pcOnly ? 5 : 12"
                    class=""
                >
                    <v-img
                        src="/assets/deploy.jpg"
                        contain
                        width="100%"
                        max-height="320"
                    ></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                    :data-aos="
                        pcOnly
                            ? isRtl
                                ? 'fade-right'
                                : 'fade-left'
                            : 'fade-up'
                    "
                    data-aos-duration="800"
                    class="mt-6 mt-lg-0"
                    :cols="pcOnly ? 6 : 12"
                >
                    <h1 class="headline">
                        {{ $t("sections.about.row3.title") }}
                    </h1>
                    <p
                        class="medium-text text-light--text mt-6 mt-lg-8"
                        :class="{ 'text-center': phoneOnly }"
                    >
                        {{ $t("sections.about.row3.content") }}
                    </p>
                </v-col>
            </v-row>
        </home-section>

        <cols-section
            id="skillsSection"
            headline="Skills"
            subtitle="Our team has a wide range of skills and expertise to help you achieve your goals."
            data-section="skills"
            :cols="skillsCols"
        >
        </cols-section>

        <the-footer />

        <scroll-to-top />
    </div>
</template>

<script>
// @ is an alias to /src
import "particles.js";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import ScrollToTop from "@/components/custom/scroll-to-top";
import AnimateText from "@/components/custom/animate-text";
import ScrollIcon from "@/components/custom/scroll-icon";
import AnimatedBackground from "@/components/custom/animated-background";
import ColsSection from "@/components/default/cols-section";
import HomeSection from "@/components/default/HomeSection";
import TheFooter from "@/components/main/TheFooter";

import GlobalComputed from "@/helpers/global-computed";
import GlobalMethods from "@/helpers/global-methods";

export default {
    name: "Home",
    components: {
        ScrollToTop,
        AnimateText,
        ColsSection,
        HomeSection,
        ScrollIcon,
        TheFooter,
        AnimatedBackground,
    },
    data() {
        return {
            showSection1Actions: false,
            currentScrollTop: 0,
        };
    },
    computed: {
        skillsCols() {
            return [
                {
                    color: "blue",
                    icon: "mdi-application-edit-outline",
                    headline: "Frontend",
                    description:
                        "Create beautiful, user-friendly interfaces using the latest web technologies and frameworks.",
                },
                {
                    color: "deep-orange",
                    icon: "mdi-database-cog",
                    headline: "Backend",
                    description:
                        "Build scalable, robust server-side applications using modern languages and frameworks, ensuring data security and performance.",
                },
                {
                    color: "teal",
                    icon: "mdi-server-network",
                    headline: "Devops",
                    description:
                        "Automate software delivery, deployment, and infrastructure management, ensuring rapid, reliable, and scalable software delivery.",
                },
            ];
        },
        industriesCols() {
            return [
                {
                    color: "blue",
                    icon: "mdi-car-outline",
                    headline: "Vehicles",
                    description:
                        "Document changes in ownership, registration, maintenance and inspection of you vehicle.",
                },
                {
                    color: "deep-orange",
                    icon: "mdi-home-outline",
                    headline: "Real Estate",
                    description:
                        "Register your property on chain and keep track of appraisals, building permits and much more.",
                },
                {
                    color: "teal",
                    icon: "mdi-brush",
                    headline: "Art",
                    description:
                        "Tokenize your real-word masterpieces and manage documents regarding authenticity, restoration etc.",
                },
            ];
        },
        ...GlobalComputed,
    },
    methods: {
        scrollToSection(n) {
            let i = n - 1,
                element = document.querySelectorAll(".page-sections section")[
                    i
                ];
            if (element) {
                this.scrollToElement(element);
            }
        },
        scrollObserver() {
            window.addEventListener("scroll", () => {
                this.currentScrollTop = document.documentElement.scrollTop;
            });
        },
        handleScrollChange(scrollTop) {
            const sections = document.querySelectorAll(
                ".page-sections section"
            );
            sections.forEach((section) => {
                let offsetTop = section.offsetTop - this.navbarHeight, // decrease navbarHeight
                    offsetBottom = offsetTop + section.offsetHeight;

                if (scrollTop >= offsetTop && scrollTop < offsetBottom) {
                    let sectionName = section.getAttribute("data-section");
                    if (sectionName) {
                        this.$store.commit("SET_ACTIVE_SECTION", sectionName);
                    }
                    // section is visible...
                    if (section.getAttribute("data-theme") === "light") {
                        if (!this.lightNavbar) {
                            this.$store.commit("SET_LIGHT_NAVBAR", true);
                        }
                    } else {
                        if (this.lightNavbar) {
                            this.$store.commit("SET_LIGHT_NAVBAR", false);
                        }
                    }
                }
            });
        },

        ...GlobalMethods,
    },
    watch: {
        currentScrollTop(top) {
            this.handleScrollChange(top);
        },
    },

    mounted() {
        this.scrollObserver();
        AOS.init({
            container: document.documentElement,
            once: true,
            easing: "ease",
        });
    },
};
</script>
<style lang="scss" scoped>
section {
    .container {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;

        &,
        * {
            z-index: 1;
        }
    }
    &.header-section {
        color: white;
    }
    h1 {
        font-size: 4rem;
        font-weight: 300;

        @media (max-width: 960px) {
            text-align: center;
            font-size: 1.7rem;
            line-height: 2.4rem;
            font-weight: 400;
        }
    }
    h2 {
        font-size: 2.75rem;
        word-break: break-word !important;
        font-weight: 200;

        @media (max-width: 960px) {
            text-align: center;
            font-size: 1.3rem;
            font-weight: 300;
        }
    }
    .actions {
        margin-top: 3rem;
        @media (max-width: 960px) {
            margin: 2rem 0;
            width: 100%;
            text-align: center;
            justify-content: center;
        }
        .v-btn {
            @media (min-width: 960px) {
                min-width: 130px;
                min-height: 45px;
            }
        }
    }
    .section-scroll-icon {
        z-index: 2;
        position: absolute;
        bottom: 8%;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>

<i18n>
{
    "en": {
        "sections": {
            "1": {
                "title": "BlockTech",
                "subtitle": "Software consulting and development for decentralized systems."
            },
            "about": {
                "title": "About",
                "subtitle": "We help you conzeptualize, design and build  your product from start to finish.",
                "row1": {
                    "title": "Design",
                    "content": "Our design phase involves working with clients to create custom software solutions tailored to their specific needs. We use agile methodologies to rapidly prototype and iterate until we arrive at a design that meets all requirements."
                },
                "row2": {
                    "title": "Build",
                    "content": "In the build phase, we take the designs created in the previous stage and turn them into working software. Our team of experienced developers use cutting-edge technologies to ensure the software is reliable, scalable, and easy to maintain."
                },
                "row3": {
                    "title": "Deploy",
                    "content": "The deploy phase is where we take the completed software and make it available to end users. We work closely with clients to ensure a smooth transition from development to deployment, providing ongoing support and maintenance to ensure the software continues to meet their needs."
                }
            },
            "actions": {
                "contact": "Contact Us",
                "about": "More About BlockTech"
            }
        }
    }
}
</i18n>
