<i18n>
{
    "en": {
        "links": "Navigation:",
        "copyrights": "Blocktech Consulting OÜ",
         "contact": {
            "subheader": "Contact:",
            "email": "Email Address",
            "website": "Website"
        },
        
        "resources": {
            "subheader": "Checkout our resources",
            "github": "Github"
        }
    }
}
</i18n>
<template>
    <footer
        class="e-footer footer-bg strong-text--text"
        :class="{ phone: phoneOnly }"
    >
        <v-container
            data-aos="fade-up"
            data-aos-duration="600"
            class="row-container text--text py-10"
        >
            <v-row>
                <v-col
                    :cols="pcOnly ? 4 : 12"
                    class="pe-lg-12"
                    :class="{
                        'd-flex text-center justify-center align-center flex-column': phoneOnly,
                    }"
                >
                    <logo width="160" />
                </v-col>
                <v-col :cols="pcOnly ? 4 : 12" class="ps-lg-12">
                    <v-subheader class="text--text">
                        {{ $t("contact.subheader") }}
                    </v-subheader>
                    <v-list color="footer-bg" dark class="text--text" two-line>
                        <v-list-item
                            v-for="item in contactItems"
                            :key="item.name"
                            :target="item.href ? '_blank' : ''"
                            :href="
                                item.href
                                    ? item.href
                                    : item.name === 'email'
                                    ? `mailto:${item.value}`
                                    : ''
                            "
                        >
                            <v-list-item-icon>
                                <v-icon :color="item.color">{{
                                    item.icon
                                }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-subtitle class="mb-2">{{
                                    $t("contact." + item.name)
                                }}</v-list-item-subtitle>
                                <v-list-item-title>{{
                                    item.value
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
        <v-spacer />
        <div class="footer-watermark flex-column">
            <v-container class="text-center py-0">
                <div>© 2023 {{ $t("copyrights") }}</div>
            </v-container>
        </div>
    </footer>
</template>

<script>
import GlobalComputed from "@/helpers/global-computed";
import GlobalMethods from "@/helpers/global-methods";
import Logo from "./logo";
import links from "@/helpers/links";

export default {
    name: "e-footer",
    props: {
        light: {
            type: Boolean,
            default: false,
        },
    },
    components: { Logo },

    data() {
        return {
            links,
            contactItems: [
                {name: 'email', icon: 'mdi-email', value: 'christian.blocktech@gmail.com', color: 'white'},
                {name: 'website', icon: 'mdi-web', value: 'blocktech-consulting.dev', color: 'text', href: 'https://blocktech-consulting.dev'},
            ],
        };
    },
    computed: {
        ...GlobalComputed,
    },
    methods: {
        ...GlobalMethods,
    },
};
</script>
<style lang="scss" scoped>
.e-footer {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-content: stretch;
    flex-direction: column;

    &.phone {
        min-height: 400px;
    }

    .footer-watermark {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.3);
        line-height: 1.6rem;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 2rem 0;
    }
    .row-container {
        line-height: 1.9rem;
    }
}
</style>
